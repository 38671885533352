







































import { Component, Vue } from "vue-property-decorator";
import PlanManagement from "./components/plan-management.vue";
import ColorManagement from "./components/color-management.vue";
import { Mutation, State } from "vuex-class";
import { calcKeyByRSA } from "@/assets/js/Signature";
@Component({
  components: {
    PlanManagement,
    ColorManagement,
  },
})
export default class SGAdminIndex extends Vue {
  private navList = [
    {
      title: "方案管理",
      icon: "el-icon-s-order",
    },
    {
      title: "色卡管理",
      icon: "el-icon-picture",
    },
    {
      title: "审核管理",
      icon: "el-icon-s-claim",
    },
    {
      title: "产品管理",
      icon: "el-icon-s-goods",
    },
    {
      title: "账号管理",
      icon: "el-icon-user-solid",
    },
    {
      title: "数据分析",
      icon: "el-icon-data-line",
    },
  ];
  private user = "管理员";
  private currentIndex = 0;
  private currentPartList = ["plan", "color"];
  @Mutation setShoppingGuideUserInfo: any;
  @State("shoppingGuideUserInfo") userInfo: any;

  get currentPart(): string {
    return this.currentPartList[this.currentIndex] + "-management";
  }

  adminLogout(): void {
    this.$confirm("确认退出?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.logout(),
        this.$message({
          type: "success",
          message: "已退出!",
        });
      })
      .catch((e) => e);
  }
  logout(): void{
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.$store.commit("$_removeStorage")
    this.$router.push({ name: "SG-AdminLogin" });
  }
  selectMenuItem(index: string): void {
    this.currentIndex = Number(index);
  }

  created(): void {
    // const userInfo = JSON.parse(localStorage.getItem("guideUserInfo") || "{}");
    // if(Object.keys(userInfo).length < 30) { //未登录
    //   this.$router.push({ name: "SG-AdminLogin" });
    // }else {
    //   const data = {
    //     refresh_token: userInfo.refresh_token
    //   }
    //   this.$post("/shopping_guide/refresh_token", data, { headers: { sign: calcKeyByRSA(data) }   }).then((res: any) => { //刷新token
    //     if(res.data.status === 1) {
    //       userInfo.token = res.data.data.token;
    //       userInfo.refresh_token = res.data.data.refresh_token;
    //       this.user = userInfo.name;
    //       this.setShoppingGuideUserInfo(userInfo);
    //     }
    //   })
    // }
  }
}
