

















































































































































































































































































































import { guideServer } from "@/assets/js/Request";
import { uploadFileToOss } from "@/assets/js/Utils";
import { Select, Loading } from "element-ui";
import { ElForm } from "element-ui/types/form";
import { Component, Vue ,Watch} from "vue-property-decorator";
import mDetail from "../../Detail/index.vue";

function validatorInput(rule: any, value: any, callback: any): void {
  if (value == "") {
    callback(new Error("该项不能为空！"));
  } else {
    callback();
  }
}

@Component({
  components: {
    mDetail,
  },
})

  

export default class PlanManagement extends Vue {
  private tableTitle = [
    "#",
    "标题",
    "标签",
    "浏览量",
    "发布时间",
    "状态",
    "操作",
  ];
  private tableData = [];
  private filterKey = [];
  private catelogList: Array<any> = [];
  private colorCardsList: Array<any> = [];
  private isCreateDialogVisible = false;
  private isPreviewerVisible = false;
  private newPlanFlag = true;
  private previewProductId = "";
  private form = {
    id: -1,
    name: "",
    productType: [],
    styleType: [],
    roomType: [],
    viewTimes: 10,
    colorSerial: "",
    substrateBrand: "",
    envLevel: "",
    substrateType: "",
    substrateInfo: "",
    colors: [
      {
        color_id: "",
        img_url: "",
        file: null,
        bind_key: this.newBindKey(),
      },
    ],
  };
  private productGroupIndex = -1;
  private limit = 12;
  private page = 1;
  private total = 0;
  private isSearch = false;

  private rules = {
    name: {
      validator: validatorInput,
      trigger: "blur",
    },
  };
  @Watch("filterKey")onFilterKeyChange(val:any){
    let filterKeys=val.toString()
    if(!filterKeys){
      if(this.isSearch){
      this.getProductList()
      }
   }

  }
  get cTypeGroup(): any {
    return this.catelogList.length > 0 ? this.catelogList[0].options : [];
  }

  get cStyleGroup(): any {
    return this.catelogList.length > 1 ? this.catelogList[1].options : [];
  }
  get cRoomGroup(): any {
    return this.catelogList.length > 2 ? this.catelogList[2].options : [];
  }

  get isTypesValid(): boolean {
    return (
      this.form.productType.length +
        this.form.styleType.length +
        this.form.roomType.length >
      0
    );
  }
 
  newBindKey(): string {
    return new Date().getTime().toString();
  }

  previewItem(id: number): void {
    // this.$router.push({ name: "SG-Preview", params: { pid: String(id) } });
    this.isPreviewerVisible = !this.isPreviewerVisible;
    this.previewProductId = String(id);
  }

  editItem(id: number, index: number): void {
    this.$get(guideServer + "/shopping_guide/admin_get_product_detail", {
      id,
    }).then((res: any) => {
      if (res.data.status == 1) {
        const data = res.data.data;
        let form = {
          id: data.id,
          name: data.name,
          productType: data.product_type,
          styleType: data.style_type,
          roomType: data.room_type,
          viewTimes: data.view_times,
          colorSerial: data.color_serial,
          substrateBrand: data.substrate_brand,
          envLevel: data.env_level,
          substrateType: data.substrate_type,
          substrateInfo: data.substrate_info,
          colors: data.colors,
        };

        form.colors.forEach((color: any) => {
          color.file = null;
          color.bindKey = this.newBindKey();
        });

        this.form = form;
        this.showCreateDialog(false);
      } else {
        this.$message({ type: "error", message: "数据获取失败，请稍后再试！" });
      }
    });
    // console.log(this.tableData[index]);
  }

  deleteItem(id: number, index: number): void {
    this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.$post(guideServer + "/shopping_guide/admin_delete_product", {
          id,
        }).then((res: any) => {
          this.tableData.splice(index, 1);
          this.$message({
            type: "success",
            message: res.data.msg,
          });
        });
      })
      .catch((e) => e);
  }

  filterTable(): void {
    if (this.filterKey.length) {
      let catelogs=this.filterKey.join(',')
      this.$get(guideServer + "/shopping_guide/admin_get_products", {
      catelogs:catelogs,
      limit: this.limit,
      page: this.page,
    }).then((res: any) => {
      const data = res.data.data;
      this.tableData = data.data;
      this.total = data.total;
      this.isSearch=true
    })
      // this.tableData = [];
      return;
    }
    (this.$refs.selector as Select).focus();
  }

  getCatelogList(): void {
    this.$get(guideServer + "/shopping_guide/admin_get_catelogs").then(
      (res: any) => {
        const data = res.data.data;
        this.catelogList = [
          {
            label: "柜体类型",
            options: data.product_type,
          },
          {
            label: "柜体风格",
            options: data.style_type,
          },
          {
            label: "房间类型",
            options: data.room_type,
          },
        ];
      }
    );
  }

  getColorCards(): void {
    this.$get(guideServer + "/shopping_guide/admin_get_colors").then(
      (res: any) => {
        console.log(this.$store.state.token)
        const data = res.data.data;
        
        this.colorCardsList = [
          {
            label: data[0].name,
            options: data[0].colors,
          },
          {
            label: data[1].name,
            options: data[1].colors,
          },
          {
            label: data[2].name,
            options: data[2].colors,
          },
        ];
      }
    );
  }

  getProductList(): void {
    this.$get(guideServer + "/shopping_guide/admin_get_products", {
      limit: this.limit,
      page: this.page,
    }).then((res: any) => {
      const data = res.data.data;
      this.tableData = data.data;
      this.total = data.total;
    });
  }

  delProductGroup(index: number): void {
    this.form.colors.splice(index, 1);
  }

  setProductGroup(index: number): void {
    this.productGroupIndex = index;
  }

  addProductGroup(): void {
    this.form.colors.push({
      color_id: "",
      img_url: "",
      bind_key: this.newBindKey(),
      file: null,
    });
  }
  handlePageChange(index: number) {
    if (this.page == index) {
      return;
    }
    this.page = index;
    if(this.isSearch){
       this.filterTable()
    }else{
       this.getProductList();  
    }
  }

  hideCreateDialog(): void {
    this.isCreateDialogVisible = false;
  }

  getRandomViewTimes(): number {
    return Math.floor(Math.random() * 100 + 10);
  }

  showCreateDialog(isNew = true): void {
    this.isCreateDialogVisible = true;
    if (isNew) {
      this.resetPlanForm();
      this.form.viewTimes = this.getRandomViewTimes();
    }
  }

  async uploadProductImage(): Promise<any> {
    const promiseList = this.form.colors
      .filter((color: any) => color.file && color.file.name && color.file.raw)
      .map((color: any) => {
        return uploadFileToOss(
          color.file,
          "shoppingGuide/productPicture/"
        ).then((filename: string) => {
          color.img_url = filename;
          return filename;
        });
      });
    return Promise.all(promiseList);
  }

  resetPlanForm(): void {
    this.form = {
      id: -1,
      name: "",
      productType: [],
      styleType: [],
      roomType: [],
      viewTimes: this.getRandomViewTimes(),
      colorSerial: "",
      substrateBrand: "",
      envLevel: "",
      substrateType: "",
      substrateInfo: "",
      colors: [
        {
          color_id: "",
          img_url: "",
          file: null,
          bind_key: this.newBindKey(),
        },
      ],
    };
  }

  createNewPlan(): void {
    (this.$refs.form as ElForm).validate((valid: any) => {
      const isColorsValid = this.form.colors.every((colorItem) => {
        return (
          (colorItem.color_id && colorItem.file) || Boolean(colorItem.img_url)
        );
      });
      if (!this.isTypesValid) {
        this.$message({ type: "error", message: "至少选择一个类型！" });
      } else if (!isColorsValid) {
        this.$message({ type: "error", message: "有待添加的效果图！" });
      } else if (valid) {
        const loader = Loading.service({
          text: "上传中",
        });

        this.uploadProductImage().then(() => {
          this.$message("图片上传成功！");
          let postData: any = {
            name: this.form.name,
            product_type: this.form.productType,
            style_type: this.form.styleType,
            room_type: this.form.roomType,
            view_times: this.form.viewTimes,
            color_serial: this.form.colorSerial,
            substrate_brand: this.form.substrateBrand,
            env_level: this.form.envLevel,
            substrate_type: this.form.substrateType,
            substrate_info: this.form.substrateInfo,
            colors: this.form.colors.map((color) => {
              return { img_url: color.img_url, color_id: color.color_id };
            }),
          };

          if (this.form.id != -1) {
            postData.id = this.form.id;
          }
          this.$post(
            guideServer + "/shopping_guide/admin_post_product",
            postData
          ).then((res: any) => {
            this.hideCreateDialog();
            loader.close();
          });
        });
      }

      return valid && this.isTypesValid && isColorsValid;
    });
  }

  handleChange(file: any): void {
    this.form.colors[this.productGroupIndex].file = file;
  }
  activated() {
    this.getCatelogList();
    this.getColorCards();
    this.getProductList();
  }
}
