

















































































































































import { guideServer } from "@/assets/js/Request";
import { Component, Vue } from "vue-property-decorator";
import gImage from "@/components/g-image.vue";
import { uploadFileToOss } from "@/assets/js/Utils";
import { Loading } from "element-ui";

@Component({
  components: {
    gImage,
  },
})
export default class ColorManagement extends Vue {
  private activePart = "1";
  private tabbarList: Array<any> = [];
  private colorList: any;

  private previewDialogVisible = false;
  private uploadDialogVisible = false;
  private formLabelWidth = "80px";
  private form = {
    name: "",
    catelog: "",
    imageUrl: "",
  };
  private formColol = {
    name: "",
    ids: 1,
    imageUrl: "",
    outIndex:1,
    inIndex:1,
    catelog:1
  };
  private disableUploader = false;
  private fileInfo: any;
  private isEdit=false;
  private colorCardName=''; // 色卡名称
  private colorcardId=1;  //色卡id
  private catelogId=1;  // 色卡类别id




  //删除色卡
  deleteColor(id: number, tabIndex: number, colorIndex: number): void {
    this.$confirm("是否删除该色卡?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.$post(guideServer + "/shopping_guide/admin_delete_color", {
          id,
        }).then((res: any) => {
          this.tabbarList[tabIndex].colors.splice(colorIndex, 1);
          this.$message({
            type: "success",
            message: res.data.msg,
          });
        });
      })
      .catch((e) => e);
  }
  editColor(id: number,name:string,catelogId:number): void {
    this.isEdit=true
    this.colorCardName=name
    this.colorcardId=id
    this.catelogId=catelogId
  }
  //色卡图片修改
  async uploadImage(): Promise<any>{
    let index = this.formColol.outIndex
    let indx = this.formColol.inIndex
      const loader = Loading.service({
          text: "上传中",
        });
      await uploadFileToOss(this.fileInfo, "shoppingGuide/colorCards/").then(
        (filename: any) => {
          this.form.imageUrl = filename;
        }
      );
      // console.log(this.form.imageUrl)
      return this.$post(guideServer + "/shopping_guide/admin_post_color", {
        id: this.formColol.ids,
        name: this.formColol.name,
        img_url: this.form.imageUrl,
        catelog_id:this.formColol.catelog
      }).then((res: any) => {
        if (res.data.status == 1) {
          // console.log(res.data.data)
          this.$message("色卡上传成功！");
          // this.getColorCatelog();
        } else {
          this.$message("色卡上传有误，请稍后重试！");
        }
        this.tabbarList[index].colors[indx].img_url = res.data.data.img_url
        loader.close();
        (this.$refs.upload as any).clearFiles();
        // console.log(this.tabbarList[index].colors[indx])
      });
    }
  checkedColor(id: number,name:string,catelog:number,tabIndex: number, coloIndex: number){
    this.formColol.ids = id,
    this.formColol.name = name,
    this.formColol.catelog = catelog,
    this.formColol.outIndex = tabIndex,
    this.formColol.inIndex = coloIndex
  }
  //修改色卡名称
  confirmModify(){
      this.isEdit=false
      this.$post(guideServer + "/shopping_guide/admin_post_color", {
      id:this.colorcardId,
      name:this.colorCardName,
      catelog_id:this.catelogId,
    }).then((res: any) => {
      if(res.status==200){
        this.$message({
          message: '修改色卡名称成功！',
          type: 'success'
        });
        this.getColorCatelog();
      }
    });
  }
  //页面渲染
  getColorCatelog(): void {
    // get_model_color_catelog
    this.$get(guideServer + "/shopping_guide/admin_get_colors").then(
      (res: any) => {
        // console.log(res)
        const data = res.data;
        // console.log(data.data)
        if (data.status == 1) {
          this.tabbarList = data.data;        }
      }
    );
  }

  hideUploadDialog(): void {
    this.uploadDialogVisible = false;
    (this.$refs.uploaders as any).clearFiles();
    this.form.catelog="";
    this.form.name="";
  }

  async uploadColorCard(): Promise<any> {
    if (this.form.name == "" && this.form.catelog == "") {
      this.$message({ type: "error", message: "请填写完整信息！" });
      return
    }

    if (!this.fileInfo) {
      this.$message({ type: "error", message: "请先添加色卡！" });
      return;
    }

    const loader = Loading.service({
      text: "上传中",
    });

    await uploadFileToOss(this.fileInfo, "shoppingGuide/colorCards/").then(
      (filename: any) => {
        this.form.imageUrl = filename;
      }
    );
    return this.$post(guideServer + "/shopping_guide/admin_post_color", {
      catelog_id: this.form.catelog,
      name: this.form.name,
      img_url: this.form.imageUrl,
    }).then((res: any) => {
      // console.log(res)
      if (res.data.status == 1) {
        this.$message("色卡添加成功！");
        this.getColorCatelog();
      } else {
        this.$message("色卡上传有误，请填写完整信息！");
      }
      this.form.catelog="";
      this.form.name="";
      (this.$refs.uploaders as any).clearFiles();
      this.hideUploadDialog();
      loader.close();
    });
  }
  handleRemove(file: any, fileList: any): void {
    // console.log(file, fileList);
    this.fileInfo = null;
    this.form.imageUrl = ""
  }

  handlePreview(file: any): void {
    this.form.imageUrl = file.url;
    this.previewDialogVisible = true;
  }

  handleExceed(file: any, fileList: any): void {
    this.disableUploader = true;
  }

  handleChange(file: any): void {
    this.fileInfo = file;
  }
  activated() {
    this.getColorCatelog();
  }
 
}
